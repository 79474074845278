import { useEffect } from 'react';
import Link from './link';

function Header() {

  useEffect(() => {
    document.title = window.Config.SiteName;
  }, []);

  return (
    <div id='header'>
      <div className='container'>
        <h1 className='logo'>
        Enot<span>Proxy</span> Uptime Status</h1>
        <input className='side-menu' type="checkbox" id="side-menu"/>
        <label className='hamb' htmlFor='side-menu'><span className="hamb-line"></span></label>
        <nav className='nav'>
        <ul className='menu'>
          {window.Config.Navi.map((item, index) => (
            <Link key={index} to={item.url} text={item.text} />
          ))}
        </ul>
        </nav>
      </div>
    </div>
  );
}

export default Header;
